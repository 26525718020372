<template>
  <div class="tgme-wrapper">
    <div class="tgme-panel">
      <div class="logo" :style="{'background-image': $filters.getItemImage(settings.logo)}"></div>
      <div class="top-info">
        <div class="username">@{{ settings.BOT_NAME }}</div>
      </div>

      <div class="content">
        <p v-if="settings.previewText" v-html="settings.previewText"></p>
        <div class="catalog-item">
          <div class="item-holder" v-if="item">
            <a href="javascript:void(0)" class="image bg-cover"
               :style="{'background-image': $filters.getItemImage(item.preview)}"></a>
            <div class="item-content">
              <div class="product-name">
                <a href="javascript:void(0)" t>
                  {{ item.title }}
                </a>
              </div>
            </div>
            <div class="price-box">
              <div class="price-old" v-if="item.old_price">
                {{ $filters.money(item.old_price) }} {{ $store.getters.getSettings.symbol }}
              </div>
              <div class="price-element">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>
            </div>
            <div class="btns">
              <div class="tgme-btn">
                <a href="javascript:void(0)" t>{{$static('tgme.open-tg', 'Відкрити у Телеграмі')}}</a>
              </div>
              <div class="default-link">
                <router-link :to="{name: 'Item', params:{category:'main', id: id}}">{{$static('tgme.no-tg', 'Немає телеграму')}}</router-link>
              </div>
            </div>
          </div>
          <div class="item-holder" v-else>
            <div class="btns">
              <div class="tgme-btn">
                <a href="javascript:void(0)" t>{{$static('tgme.open-tg', 'Відкрити у Телеграмі')}}</a>
              </div>
              <div class="default-link">
                <router-link :to="{name: 'home'}">{{$static('tgme.no-tg', 'Немає телеграму')}}</router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="tgme-bg" style="background-image:url('/images/tg-pattern.png');"></div>
    <div class="gradient"></div>
  </div>
</template>

<script>
export default {
  name : 'Subscribe',
  props: ['id'],
  data() {
    return {
      item: undefined
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    }
  },
  methods : {
    fetchSettings() {
      return fetch('/public/settings').then(response => response.json()).then(
          data => {
            if (data) {
              this.$store.commit('setSettings', data);
            }
          })
    },
    fetchItem(id) {
      return fetch('/public/item/' + id).then(response => response.json()).then(
          data => {
            if (data && data.result) {
              this.item = data.result;
            }
          })
    },

    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    }
  },
  mounted() {
    let settings  = this.fetchSettings();
    let awaitList = [settings];
    if (this.id) {
      awaitList.push(this.fetchItem(this.id))
    }
    Promise.all(awaitList).then(() => {
      window.$('.preloader-page').removeClass('proccess-load')

      let addLink = ''
      let dLink   = '/'
      if (this.settings.DIRECT_LINK) {
        dLink = this.settings.DIRECT_LINK
      }
      let directLink = dLink.split('/')
      if (directLink.length === 5) {
        let bot_name = directLink[3]
        let app_name = directLink[4]
        addLink      = `${bot_name}&appname=${app_name}`
      } else {
        addLink = this.settings.BOT_NAME
      }

      Array.from(document.querySelectorAll('a[t]')).forEach(i => {
        i.href = 'tg://resolve?domain=' + addLink
      })
    })
  },
  watch: {
    settings(val) {
      if (val && val.favicon) {
        let link = document.querySelector('link[rel~=\'icon\']');
        if (link) {
          link.href = val.favicon
        }
      }
    }
  }
}
</script>

<style scoped>
.logo {
  background-size : contain;
}
</style>