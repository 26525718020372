import { createStore } from 'vuex';

let saveBusked = (item, user) => {
  window.localStorage.setItem('_BUSKED', JSON.stringify(item))
  if (user) {
    fetch('/public/set_busked', {
      method: 'POST',
      body  : JSON.stringify({user: user, busked: item})
    })
  }
};

let busked = [];
(() => {
  let StartBUSKED = window.localStorage.getItem('_BUSKED');
  if (StartBUSKED) {
    busked = JSON.parse(StartBUSKED);
    if (busked.length > 0) {
      busked = busked.map((i) => {
        if (i.additionSelect && i.additionSelect.length) {
          i.additionSelect = i.additionSelect.filter((x) => x);
        }
        return i;
      });
    }
  }
  window.busked = busked;
})();

window.addition_price  = function (item) {
  return item.additionSelect ? item.additionSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
}
window.addition_Uprice = function (item) {
  return item.additionUSelect ? item.additionUSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
}
window.selectedPrice   = function (item) {
  if (item.selectedMod > 0) {
    return item?.advanced?.modList[item.selectedMod].price ? item.advanced.modList[item.selectedMod].price : 0
  }
  return item.price
}
window.itemPrice       = function (item) {
  return (item.price * item.count) + this.addition_price(item) + this.addition_Uprice(item)
}

export default createStore(
  {
    state    : {
      iswebapp       : false,
      userInfo       : undefined,
      account        : undefined,
      isBuskedOpen   : false,
      categories     : [],
      searchBy       : [],
      settings       : [],
      tags           : [],
      options        : {},
      optionsSettings: {},
      addition       : [],
      busked         : busked,
      deliveryOptions: [],
      payment        : [],
      pages          : {},
      landing        : undefined,
      reviews        : [],
      
      deliveryInfo    : undefined,
      deliverySelected: undefined
    },
    getters  : {
      isWebApp          : (state) => state.iswebapp,
      getCategories     : (state) => state.categories,
      getSearchBy       : (state) => state.searchBy,
      getReviews        : (state) => state.reviews,
      getBuskedOpen     : (state) => state.isBuskedOpen,
      getSettings       : (state) => state.settings,
      getOptions        : (state) => state.options,
      getOptionSettings : (state) => state.optionsSettings,
      getTags           : (state) => state.tags.sort((a, b) => a.order - b.order),
      getAddition       : (state) => state.addition,
      getBusked         : (state) => state.busked,
      getBuskedTotal    : (state) => state.busked.reduce((c, item) => c + window.itemPrice(item), 0),
      getDeliveryOptions: (state) => state.deliveryOptions,
      getPayment        : (state) => state.payment,
      getPages          : (state) => state.pages,
      getUserInfo       : (state) => state.userInfo,
      getAccount        : (state) => state.account,
      landing           : (state) => state.landing,
      
      getDeliveryInfo    : (state) => state.deliveryInfo,
      getDeliverySelected: (state) => state.deliverySelected
      
    },
    mutations: {
      setIsWebApp       : (state, payload) => state.iswebapp = payload,
      setCategories     : (state, payload) => state.categories = payload,
      setSearchBy       : (state, payload) => state.searchBy = payload,
      setReviews        : (state, payload) => state.reviews = payload,
      setBuskedOpen     : (state, payload) => state.isBuskedOpen = payload,
      setSettings       : (state, payload) => state.settings = payload,
      setTags           : (state, payload) => state.tags = payload,
      setAddition       : (state, payload) => state.addition = payload,
      setBonus          : (state, payload) => {
        if (state.account) {
          state.account.bonus = payload
        }
      },
      setOptions        : (state, payload) => {
        state.options[payload.option_id] = payload
      },
      setOptionSettings : (state, payload) => {
        state.optionsSettings = payload
      },
      setDefaultPayment : (state, payload) => state.defaultPayment = payload,
      setDefaultDelivery: (state, payload) => state.defaultDelivery = payload,
      setPayment        : (state, payload) => state.payment = payload,
      setDelivery       : (state, payload) => state.delivery = payload,
      setLanding        : (state, payload) => state.landing = payload,
      setUserInfo       : (state, payload) => state.userInfo = payload,
      setAccount        : (state, payload) => state.account = payload,
      setPage           : (state, payload) => {
        if (payload.name) {
          state.pages[payload.name] = payload
        }
      },
      addToBasked       : (state, payload) => {
        state.busked.push(payload);
        saveBusked(state.busked, state.userInfo);
      },
      replaceBuskedIndex: (state, payload) => {
        if (payload.index > -1 && state.busked.length > payload.index) {
          state.busked[payload.index] = payload.value
        }
        saveBusked(state.busked, state.userInfo);
      },
      
      delBasked  : (state, payload) => {
        state.busked.splice(payload, 1);
        saveBusked(state.busked, state.userInfo);
      },
      clearBasked: (state) => {
        state.busked = [];
        saveBusked(state.busked, state.userInfo);
      },
      
      setDeliveryInfo    : (state, payload) => (state.deliveryInfo = payload),
      setDeliverySelected: (state, payload) => (state.deliverySelected = payload)
    }
  });
