<template>
  <div class="catalog-wrapper wrapper">

    <div class="custom-header category-header bg-cover" v-if="categoryImage"
         :style="{'background-image': $filters.getItemImage(categoryImage)}">
      <!--      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
    </div>
    <div class="category-header custom-header min-header" v-else>
      <!--      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
    </div>
    <div class="cat-title">
      <h1>{{ $dynamic(categoryObject, categoryName, 'name') }}</h1>
    </div>
    <div class="sorting-panel" v-if="tags && tags.length || (groups && groups.length)">
      <div class="sort-list">
        <div class="sorting-checkbox">
          <input id="all" type="checkbox" v-model="all_tags" value="1">
          <label for="all">
            <span class="text">{{ $static('options.all', 'Усі') }}</span>
          </label>
        </div>

        <div class="sorting-checkbox" v-for="(op, index) in short_tags" :key="index">
          <input v-if="op" :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
          <label v-if="op" :for="op.id">
            <span class="pick" v-if="op.image" :style="{'background-image': $filters.getItemImage(op.image)}"></span>
            <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
            <span class="text">
              <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
              <span class="custom-option-price">
                <span class="price" v-if="op.price">
                  {{ $filters.money(op.price) }} {{ $store.getters.getSettings.symbol }}
                </span>
                <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }} {{
                    $store.getters.getSettings.symbol
                  }}</span>
              </span>
            </span>
          </label>
        </div>
        <div class="filter-trigger-holder" v-if="short_tags && short_tags.length > 3 || groups?.length">
          <a href="javascript:void(0);" @click="toggleFilter" class="filter_trigger">
            <span class="text">Відкрити фільтр</span>
            <img src="/images/icons/filter.svg" alt="">
          </a>
        </div>
      </div>
    </div>

    <div class="catalog-grid-holder">
      <ul class="catalog-grid" :class="{'double-items': $store.getters.getSettings.catalog_duo}">
        <li class="catalog-item" v-for="(item, index) in items" :key="index">
          <div class="item-holder" :class="{'not-available': !item.stock}" v-if="categoryURL">
            <div class="product-label label-new" v-if="isHot(item)">
              <span class="label-text">New</span>
            </div>
            <div class="product-label label-sale" v-if="item.price_old && getDiscount(item) > 0">
              <div class="tile_label tile_sale">-{{ getDiscount(item) }}%</div>
            </div>
            <router-link :to="{ name: 'Item', params: { category: categoryURL, id: item.id }, query: $route.query }"
                         class="image bg-cover"
                         :style="{'background-image': $filters.getItemImage(item.preview)}"></router-link>
            <div class="item-content">
              <div class="product-name">
                <router-link :to="{ name: 'Item', params: { category: categoryURL, id: item.id }, query: $route.query }">
                  {{ $dynamic(item, item.title, 'title') }}
                </router-link>

              </div>
            </div>
            <div class="options">
              <div class="price-box">
                <div class="price-element" v-if="item.price > 0"> {{ $filters.money(item.price) }}
                  {{ $store.getters.getSettings.symbol }}
                </div>
                <div class="price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }}
                  {{ $store.getters.getSettings.symbol }}
                </div>
              </div>
              <div class="right-options" v-if="categoryURL">
                <router-link :to="{ name: 'Item', params: { category: categoryURL, id: item.id }, query: $route.query }"
                             class="default-btn">
                  <span class="btn-text">{{ $static('catalog.info', 'Детальніше') }}</span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"
                        fill="white"/>
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </li>
        <div ref="loadMore"></div>
      </ul>
    </div>
  </div>

  <div class="fixed-filter-trigger">
    <a href="javascript:void(0);" @click="toggleFilter" class="filter_trigger">
      <img src="/images/icons/filter.svg" alt="">
    </a>
  </div>

  <div class="filter_sidebar" :class="{'opened': filterState}">
    <div class="filter_sidebar_wrapper">
      <div class="panel-top">
        <div class="panel-top-name">{{ $static('catalog.filter', 'Фільтри') }}</div>
        <a href="javascript:void(0);" @click="toggleFilter" class="close_filter">
          <img src="/images/icons/close.svg" alt="">
        </a>
      </div>

      <div class="panel-middle">
        <div class="filter-list">
          <div class="filter-holder">

            <div class="filter-panel">
              <div class="sorting-panel" v-if="(tags && tags.length)">
                <div class="sort-list">
                  <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
                    <input v-if="op" :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
                    <label v-if="op" :for="op.id">
                      <span class="pick" v-if="op.image" :style="{'background-image': $filters.getItemImage(op.image)}"></span>
                      <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
                      <span class="text">
                        <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
                        <span class="custom-option-price">
                          <span class="price" v-if="op.price"> {{ $filters.money(op.price) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                          <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="sorting-panel" v-for="(group, gIndex) in groups" :key="gIndex">
                <div class="custom-label">
                  {{ $dynamic(group, group.name, 'name') }}
                </div>
                <div class="sort-list">
                  <div class="sorting-checkbox" v-for="(op, index) in group.list" :key="index">
                    <input v-if="op" :id="gIndex+'_'+op.id" type="checkbox" v-model="optionSelect" :value="op.id">
                    <label v-if="op" :for="gIndex+'_'+op.id">
                      <span class="pick" v-if="op.image" :style="{'background-image': $filters.getItemImage(op.image)}"></span>

                      <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
                      <span class="text">
                        <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
                        <span class="custom-option-price">
                          <span class="price" v-if="op.price"> {{ $filters.money(op.price) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                          <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }}
                            {{ $store.getters.getSettings.symbol }}
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="panel-bottom">
        <button href="javascript:void(0)" @click="toggleFilter" class="default-btn reload_filter">
          <span class="text">Скинути</span>
          <img src="/images/icons/refresh.svg">
        </button>
        <button href="javascript:void(0)" @click="toggleFilter" class="default-btn btn-accent apply-filter">
          <span class="text">Застосувати</span>
        </button>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <div class="frame-modal-wrapper-bg" @click="toggleFilter" :class="{'is-visible': filterState}"></div>
  </Teleport>
</template>

<script>
import { fixBodyPadding } from '@/utils/fixBodyPadding';

export default {
  name : 'Catalog',
  props: ['category'],
  data() {
    return {
      optionSelect: [],
      items       : [],
      filterState : false,
      load        : false,
      page        : 1,
      perPage     : 12,
      total       : 0,
      more        : true,
      checkLoader : undefined,
      loadBefore  : 0,
      loadTimeout : undefined
    }
  },
  beforeUnmount() {
    window.document.removeEventListener('scroll', this.onscroll);
  },
  updated() {
    if (this.more) {
      this.loadBefore = this.checkLoader.offsetTop;
    } else {
      let body        = document.body, html = document.documentElement;
      this.loadBefore = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) + 1000;
    }
  },
  mounted() {
    this.checkLoader = this.$refs.loadMore;
    window.document.addEventListener('scroll', this.onscroll);

    this.getItems();
    this.setSeo(this.categoryObject);

    this.$ViewCategory(this.categoryObject)
  },
  methods : {
    toggleFilter() {
      this.filterState = !this.filterState
    },
    onscroll(e) {
      if (document.documentElement.scrollTop + window.innerHeight > this.loadBefore && this.more) {
        this.page += 1;
        this.getItems();
      }
    },
    getColor(item) {
      return `background-color: ${item.color};`
    },
    isHot(item) {
      return item.advanced && item.advanced.hot
    },
    loadLater(clear) {
      console.log('load later...')
      if (this.loadTimeout) {
        clearTimeout(this.loadTimeout)
      }
      this.loadTimeout = setTimeout(() => {
        this.load = false
        this.getItems(clear)
      }, 100)
    },
    getItems(clear) {
      if (this.settings.symbol === undefined) {
        this.loadLater(clear);
        return;
      }
      if (this.load) {
        return;
      }
      this.load = true;
      if (clear) {
        this.page = 1;
        this.more = true;
      }
      let filter = {page: this.page, perPage: this.perPage};
      if (this.searchBy.length) {
        filter.category = this.searchBy
      } else if (this.settings.menu_type === 'advanced') {
        this.loadLater(clear)
        return;
      } else if (this.categoryName) {
        filter.category = this.categoryObject.id
      } else {
        filter.url = this.category
      }

      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.$store.getters.getTags.find(o => o.id === id)).map(x => x ? x.id : x);
      }

      fetch('/public/item/filter', {
        method : 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body   : JSON.stringify({filter: filter, sortBy: 'sort'})
      }).then(response => response.json()).then(data => {
        this.load = false;
        if (clear) {
          this.items = data.result;
        } else {
          this.items.push(...data.result);
        }
        this.page  = data.page;
        this.total = data.total;
        if (this.items.length === this.total) {
          this.more = false;
        }
      }).catch(e => {
        this.load = false;
      })
    },
    getDiscount(item) {
      return item ? (1 - Math.max(item.price / item.price_old, 0)) * 100 | 0 : ''
    },
    setSeo(val) {
      if (val && val.info) {
        let title = val.info.title ? val.info.title : this.$dynamic(this.categoryObject, this.categoryName, 'name');

        document.querySelector('title').innerText = title;
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);

        let description = val.info.description;
        document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
        document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');
        let image = val.image ? `${window.location.protocol}//${window.location.host}${val.image}` : '';
        document.querySelector('meta[property="og:image"]').setAttribute('content', image);
      }
    }
  },
  computed: {
    // tags() {
    //   return this.$store.getters.getTags.filter(x => x.info ? !x.info.hidden : true)
    // },
    settings() {
      return this.$store.getters.getSettings
    },
    categoryGroups() {
      return this.categoryObject?.info?.groups
    },
    groups() {
      return this.categoryGroups ?
          this.categoryGroups.map(group => {
            let list = group.list.map(id => this.storeTags.find(i => i.id === id)).filter(i => i && i.id)
            return Object.assign({}, group, {list: list})
          }) : this.categoryGroups
    },
    searchBy() {
      return this.$store.getters.getSearchBy
    },
    categoryURL() {
      return this.categoryObject?.info?.url
    },
    categoryTags() {
      return this.categoryObject?.info?.tags
    },
    tags() {
      return this.categoryTags ?
          this.categoryTags.map(id => this.storeTags.find(i => i.id === id)).filter(i => i && i.id)
          : []
    },
    short_tags() {
      return this.tags.slice(0, 3)
    },
    storeTags() {
      return this.$store.getters.getTags
    },
    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    },
    categoryObject() {
      return this.$store.getters.getCategories.find(i => i.info.url === this.category)
    },
    categoryName() {
      return this.categoryObject ? this.categoryObject.name : ''
    },
    categoryImage() {
      return this.categoryObject && this.categoryObject.image ? this.categoryObject.image : ''
    }
  },
  watch   : {
    category() {
      this.$ViewCategory(this.categoryObject)

      this.getItems(true)
    },
    optionSelect(val) {
      this.getItems(true)
    },
    categoryObject(val) {
      this.setSeo(val)
    }
  }
}
</script>

<style scoped>
.catalog-item .not-available .options,
.catalog-item .not-available .product-name {
  opacity : 0.6;
}

.catalog-item .not-available .image {
  filter : grayscale(100%);
}
</style>